.listEntry{
    padding:20px;
    margin: 10px;
    background-color: #ffffff;
    border-radius: 25px;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
}

.cardImage{
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    margin:30px;
    width:90%;
}

.searchBar{
    padding-bottom:50px;
}

.modalButton{
    width: 20%;
}

.modalBody{
    display:flex;
    flex-direction:column;
    text-align:left;
}

.large-modal-content {
    max-width: 90%;
    width: auto;
}

.searchBar{
    padding:50px;
}