.sliderContainer {
    padding: 5%;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 25px;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
}

.sliderTarget{
    margin-top:5px;
    margin-bottom:20px;
}

.buttonContainer{
/*    aligns the button on the right side of the screen */
    display: flex;
    justify-content: flex-end;
}