.chart{
    /*!*border: 1px solid black;*!*/
    background-color: #ffffff;
    height: 100%;
    padding: 2.5%;
    border-radius:25px;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
    /*flex-grow:1;*/
    /*@media (max-width: 768px) {*/
    /*    padding:20px;*/
    /*}*/
}